<template>
  <v-main>
    
    <router-view />

    
  </v-main>
</template>

<script>
import DashboardCoreFooter from './Footer'

  export default {
    name: 'DashboardCoreView',

    components: {
      DashboardCoreFooter
    }
   
  }
</script>
