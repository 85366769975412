<template>
  <v-app>
  <div v-if="isLoggedIn == true">
  
    <dashboard-core-app-bar />

    <dashboard-core-drawer />
    </div>

    <dashboard-core-view />
  </v-app>
</template>

<script>
import DashboardCoreAppBar from "./components/core/AppBar"
import DashboardCoreDrawer from "./components/core/Drawer"
import DashboardCoreView from "./components/core/View"

export default {
  name: "DashboardIndex",

  components: {
    DashboardCoreAppBar,
    DashboardCoreDrawer,
    DashboardCoreView,
  }, mounted(){
      var view =this
      view.isLoggedIn = view.$utils.isLoggedIn()
      //alert(view.$utils.getBaseURL())
    },

  data: () => ({
    expandOnHover: false,
    isLoggedIn:''
  }),
};
</script>
