<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-break-point="960"
    app
    width="260"
    @transitionend="computedItems"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-divider class="mb-1" />

    <v-list dense nav class="logoDiv">
      <v-list-item>
        <v-list-item-avatar class="align-self-center" color="white" contain>
          <v-img rel="preload" src="@/assets/logo.3bf2080c.png" max-height="30" />
        </v-list-item-avatar>
      </v-list-item>
      <!-- <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="display-1" v-text="profile.title" />
        </v-list-item-content>
      </v-list-item> -->
    </v-list>

    <v-divider class="mb-2" />

    <v-list nav>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->

      <template v-for="(item, i) in computedItems">
        <v-list-group v-if="item.children.length" v-model="item.active" @click="collapseOther()" :key="item.module_id"  :value="checkIfDropdown(item.children)">
          <template v-slot:activator>
            <v-list-item-content >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="(child, j) in item.children"
            :key="j"
            :to="child.to"
          >
            <v-list-item-icon>
              <v-icon small >mdi-view-dashboard</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item v-else :key="`item-${i}`" :to="item.to">
          <v-list-item-icon>
             <v-icon small >mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>

    <template v-slot:append>
      <v-row
    align="center"
    justify="space-around"
  >
    <!-- <v-btn
      block
      color="primary"
      @click="logout"
    >
      <v-icon left>
        mdi-logout
      </v-icon>
      Log Out
    </v-btn> -->
  </v-row>
    </template>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from "vuex";
import { Auth } from "aws-amplify";
import { mapGetters } from "vuex";
import axios from 'axios';
export default {
  name: "DashboardCoreDrawer",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    is_signed_in: false,
  }),
  mounted() {
     if (this.$utils.isValidToken() === true){
          this.getRolesAndPermissionData();
      }
  },
  computed: {
    ...mapState(["barColor", "barImage"]),
    ...mapGetters({
      items: 'Rawdata/getSidebar',
    }),
    drawer: {
      get() {
        return this.$store.getters['Rawdata/getDrawer'];
      },
      set(val) {
        this.$store.dispatch("Rawdata/setDrawer", val)
      },
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        title: this.$t("avatar"),
      };
    },
  },
  methods: {
    collapseOther(){
      this.computedItems.forEach(item => {
        if (item.active) {
          _.delay(function () {
            item.active = false
          }, 300)
          return false
        }
      })
    },
    getRolesAndPermissionData: async function(){
      let check = this
      let json_data = {}
      let complete_sidebar = []
        let path = this.$url("USERROLEANDPERMISSIONS")+'/1';
        let permission_endpoint = this.$url("USERROLEANDPERMISSIONS")+'/2';
        // let frontend_modules = []
        // this.$fetch({ requiresAuth: true, operation : 'view', vueScope: check })
        // .get(permission_endpoint).then((resp)=>
        // {
        //   resp.data.payload.user_role.modules.forEach(modules => {
        //     frontend_modules.push(modules.module_id)
        //   })

        //   console.log(resp.data.payload.user_role.modules)
        // })
        // check.$store.dispatch("Rawdata/setContentSiteModules",frontend_modules)

        let current_active_module = await this.$fetch({ requiresAuth: true, operation : 'view', vueScope: check })
        .get(path)
        .then((res) => {
          check.$store.dispatch("Rawdata/setAdminSiteModules",res.data.payload.user_role.modules)
          let current_active_mod = null
          json_data = res.data.payload
          check.$store.dispatch("Rawdata/setUserId", json_data.user_id)
          check.$store.dispatch("Rawdata/setSectors", json_data.user_role.sectors)

      if(json_data.user_role){
        check.$utils.setCookies('user_role', json_data.user_role.role_id, check)
        let user_role = json_data.user_role
        let link_names = []
          if(user_role.modules.length){
            user_role.modules.forEach(module => {
              if(module.module_parent_id == 0){
                let children = []
                user_role.modules.forEach(module_copy => {
                  if(module_copy.module_parent_id == module.module_id){
                    let children_module = {
                      icon: module_copy.icon,
                      title: module_copy.module_name,
                      to: "../" + module_copy.module_url_slug.trim(),
                      module_sort : module_copy.module_sort,
                      module_parent_id: module_copy.module_parent_id,
                      module_id: module_copy.module_id,
                      operations: module_copy.operations,
                    }
                    // console.log(check.$route.path.toLowerCase(),module_copy.module_url_slug.trim().toLowerCase(), check.$route.path);
                    if(check.$route.path.toLowerCase().includes(module_copy.module_url_slug.trim().toLowerCase()) && check.$route.path != '/'){
                      current_active_mod = children_module
                      //check.$store.dispatch("Rawdata/setCurrentActiveModule", children_module)
                    }
                    link_names.push(module_copy.module_name)
                    if(module_copy.internal_module == 0){
                      children.push(children_module)
                    }
                  }
                })
                children = children.sort((a, b) => a.module_sort > b.module_sort ? 1 : b.module_sort > a.module_sort ? -1 : 0 )
                let sidebar_module = {
                  icon: module.module_icon,
                  title: module.module_name,
                  to: '../' + module.module_url_slug.trim(),
                  module_sort : module.module_sort,
                  module_parent_id: module.module_parent_id,
                  module_id: module.module_id,
                  active:false,
                  children: children,
                  operations: module.operations,
                }
                if(!link_names.includes(sidebar_module.title)){
                  if(check.$route.path.toLowerCase().includes(module.module_url_slug.trim().toLowerCase()) && check.$route.path != '/'){
                    console.log('set module id');
                    current_active_mod = sidebar_module
                      //check.$store.dispatch("Rawdata/setCurrentActiveModule", sidebar_module)
                    }
                    link_names.push(sidebar_module.title)
                    if(module.internal_module == 0){
                      complete_sidebar.push(sidebar_module)
                    }
                }
              }else{
                let parent_icon = ''
                let parent_title = ''
                let parent_to = ''
                let parent_sort = ''
                let parent_id = ''
                let similar_children = []
                let internal_module = 0
                user_role.modules.forEach(module_copy => {
                  if(module_copy.module_parent_id == module.module_parent_id){
                    //if similar child
                    let children_module = {
                      icon: module_copy.module_icon,
                      title: module_copy.module_name,
                      to: "../" + module_copy.module_url_slug.trim(),
                      module_sort : module_copy.module_sort,
                      module_parent_id: module_copy.module_parent_id,
                      module_id: module_copy.module_id,
                      operations: module_copy.operations,
                    }
                    if(check.$route.path.toLowerCase().includes(module_copy.module_url_slug.trim().toLowerCase()) && check.$route.path != '/'){
                      current_active_mod = children_module
                    }
                    link_names.push(module_copy.module_name)
                    if(module_copy.internal_module == 0){
                      similar_children.push(children_module)
                    }
                  }else if(module.module_parent_id == module_copy.module_id){
                      parent_icon = module_copy.module_icon
                      parent_title = module_copy.module_name
                      parent_to = '../' + module_copy.module_url_slug.trim()
                      parent_sort = module_copy.module_sort
                      parent_id = module_copy.module_id
                      internal_module = module_copy.internal_module
                  }
                })
                similar_children = similar_children.sort((a, b) => a.module_sort > b.module_sort ? 1 : b.module_sort > a.module_sort ? -1 : 0 )
                let parent_sidebar_module = {
                  icon: parent_icon,
                  title: parent_title,
                  to: parent_to.trim(),
                  module_sort : parent_sort,
                  module_parent_id: 0,
                  active:false,
                  module_id: parent_id,
                  children: similar_children,
                  operations: [],
                  internal_module: internal_module,
                }
                if(!link_names.includes(parent_sidebar_module.title))
                {
                  if(check.$route.path.toLowerCase().includes(parent_to.trim().toLowerCase()) && check.$route.path != '/'){
                    console.log('set module id');
                      current_active_mod = parent_sidebar_module
                      //check.$store.dispatch("Rawdata/setCurrentActiveModule", parent_sidebar_module)
                    }
                    link_names.push(parent_sidebar_module.title)
                    if(parent_sidebar_module.internal_module == 0){
                      complete_sidebar.push(parent_sidebar_module)
                    }
                }
              }
            })
          };
        complete_sidebar = complete_sidebar.sort((a, b) => a.module_sort > b.module_sort ? 1 : b.module_sort > a.module_sort ? -1 : 0 )
        //console.log(complete_sidebar);
        console.log(current_active_mod);
        if(current_active_mod != null){
          let active_parent_id = current_active_mod.module_parent_id
        complete_sidebar.forEach((module)=>{
          if(active_parent_id == module.module_id){
            module.active = true
            //console.log(module.title);
          }
        })
        }
        else{
          if(check.$route.path != '/'){
            alert("You are not authorized!")
            check.$router.push({ path: "/" });
          }
        }
        check.$store.dispatch("Rawdata/setSidebar", complete_sidebar)
        return current_active_mod
      }}).catch((error) => {
          console.error(error);
        });
        check.$store.dispatch("Rawdata/setCurrentActiveModule", current_active_module)
      },
    // logout() {
    //     var view = this
    //     try {
    //           let signout_url = view.$url('SIGNOUT_URL')
    //           axios.get(signout_url, {
    //               headers: {
    //                 Authorization: this.$utils.getCookies('access_token'),
    //                 Userid : this.$utils.getCookies('user_id')
    //               }
    //           }).then(res => {
    //               if(res.data.statusCode === 200){
    //                     view.$utils.setCookies('isSignedIn', false,view)
    //                     view.$utils.removeCookies('access_token',view)
    //                     view.$utils.removeCookies('lastVisitedUrl',view)
    //                     view.$utils.removeCookies('email',view)
    //                     view.$utils.removeCookies('username',view)
    //                     view.$utils.removeCookies('user_id',view)
    //                     view.$utils.removeCookies('un_pass',view)
    //                     view.$utils.removeCookies('refresh_token',view)
    //                     view.$utils.removeCookies('portal_access',view)
    //                     view.$utils.removeCookies('token_expire_time',view)
    //                     view.$utils.removeCookies('startTime',view)
    //                     view.$utils.removeCookies('precedence_url',view)
    //                     view.$utils.removeCookies('user_role',view)
    //                     location.href = view.$url('SIGNIN_URL')
    //                 }
    //             }).catch((error) => {
    //               console.error(error);
    //             });
    //         }catch(error){
    //           console.log('error signing out: ', error)
    //         }
    // },
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
    checkIfDropdown(item){
      let res = false
        item.forEach(o => {
          let str = this.$route.path.toLowerCase();
          const myArr = str.split("/");
          //console.log(o.to.toLowerCase(),this.$route.path.toLowerCase() );
          if(o.to.toLowerCase().includes(myArr[1]) && this.$route.path != '/'){
            res = true
          }
        })
        return res
    },
  },
};
</script>

<style>
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 0px !important;
}
.v-application--is-ltr
  #core-navigation-drawer
  div.v-list-item__icon:first-child {
  margin-left: 0px !important;
}
.v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) {
  margin-left: 12px !important;
}
.v-list-group
  .v-list-group__header
  .v-list-item__icon.v-list-group__header__append-icon {
  min-width: 15px !important;
}
.v-application--is-ltr .v-list-item__avatar:first-child {
  width: 100% !important;
}
.v-avatar {
  border-radius: 1% !important;
}
.v-list--nav .v-list-item:not(:last-child):not(:only-child),
.v-list--rounded .v-list-item:not(:last-child):not(:only-child) {
  margin-bottom: 2px !important;
}
.v-list--nav .v-list-item {
  padding: 0px 3px !important;
}
.logoDiv {
  background-color: #ffffff !important;
}
.display-1 {
  color: #fff;
}
/* .theme--dark.v-navigation-drawer {
  background-color: #f8f8f8!important;
} */
.theme--dark.v-navigation-drawer {
    background-color: #ffffff!important;
}
.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #2d2d2d !important;
}
.theme--dark.v-icon {
  color: #2d2d2d !important;
}
#core-navigation-drawer .v-list .v-list-item--active {
  background-color: #3f51b5;
    color: #ffffff !important;
}
.v-list .v-list-item--hover {
  background-color: #3f51b5;
    color: #ffffff !important;
}
.v-list-group--active {
  padding: 4px;
  border: 1px solid #d4d4d4;
}
.v-list .v-list-item--active .theme--dark.v-icon, .v-list .v-list-item--hover .theme--dark.v-icon {
    color: #ffffff !important;
}
.v-list-group {
  margin:8px;
  padding: 2px;
  border: 1px solid #d4d4d4;
}
.v-application
  .v-navigation-drawer
  .v-navigation-drawer__content
  .v-list
  .v-list-group
  .v-list-group__header
  .v-list-item__content
  .v-list-item__title {
  font-size: 15px !important;
  font-weight: 400 !important;
}
.theme--dark.v-list {
    padding-top: 0px;
}
.v-list-item .v-list-item__title {
  font-size: 15px !important;
}
.v-list-item__content{
  padding: 5px 4px!important;
}
.v-navigation-drawer .v-icon.v-icon {
    font-size: 15px!important;
}
.v-application--is-ltr #core-navigation-drawer div.v-list-item__icon:first-child {
    margin-left: 0px !important;
    margin-right: 0px;
    opacity: 0.8;
}
.v-list-item {
    min-height: 5px;
}
.v-list-group__items .v-list-item .v-list-item__icon {
    margin-top: 5px!important;
}
.v-list-item__icon {
    margin: 0px 0!important;
}
.v-list-item .v-list-item__title {
    font-size: 13px !important;
}
.v-list {
    padding: 0px 0;
}
</style>
