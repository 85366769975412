<template>
  <div class="ml-5 mr-5">
    <div class="text-right my-4">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
      <template>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small class="mx-2" color="primary" dark v-bind="attrs" v-on="on" v-show="checkIfOperationExistForModule('add')">
              Add
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Add State/Province Name</span>
            </v-card-title>
            <v-form
              ref="form"
              @submit="addProvinceData"
              method="post"
              v-model="valid"
              lazy-validation
            >
              <v-container class="px-50" fluid>
                <v-text-field
                  v-model="addProvince.stateName"
                  label="Province Name"
                  :rules="nameRules"
                  required
                ></v-text-field>
                <div style="color: red">
                  {{ message1 }}
                </div>
                <v-text-field
                  v-model="addProvince.stateCode"
                  :counter="2"
                  label="State/Province Code"
                ></v-text-field>
                <v-select
                  v-model="addProvince.countryCode"
                  :items="states"
                  :menu-props="{ maxHeight: '400' }"
                  label="Country"
                ></v-select>
                <v-text-field
                  v-model="addProvince.panelist_stateID"
                  :counter="4"
                  label="Panelist Code"
                ></v-text-field>
              </v-container>
              <v-container class="px-50" fluid> </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" type="submit" @click="validate">
                  Submit
                </v-btn>
                <v-btn class="close_button mx-2" text @click="reset"> Close </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </template>
      <v-dialog max-width="600" v-model="dialog2">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small color="error" v-bind="attrs" v-on="on" v-show="checkIfOperationExistForModule('delete')">Delete</v-btn>
        </template>
        <div v-if="deleteItems.length > 0">
          <template>
            <v-card>
              <v-card-text>
                <div class="text-h3 pa-12">
                   Are you sure you want to delete all the selected Record(s)?
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-form ref="form" method="post">
                  <v-btn
                    text
                    class="confirm_button mx-2"
                    v-on:click="dialog2 = false"
                    @click="deleteProvince"
                    >Yes</v-btn
                  >
                </v-form>
                <v-btn class="close_button mx-2" text @click="dialog2 = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </div>
        <div v-if="deleteItems.length == 0">
          <template>
            <v-card>
              <v-card-text class="center">
                <div class="text-h3 pa-12">
                  Please Select Province/State to delete
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn class="close_button mx-2" text @click="dialog2 = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </div>
      </v-dialog>
    </div>
    <div class="text-right"></div>
 <!-- v-data-table  -->
    <v-row>
      <v-col cols="12">
          <div class="tableWrapper">
            <div id="select-all-checkbox">
              <v-checkbox
                ref="n"
                v-model="allSelected"
                @click="selectAll"
                v-show="checkIfOperationExistForModule('delete')"
              ></v-checkbox>
            </div>
            <v-data-table
              :headers="headers"
              item-key="product_id"
              :items="province_data"
              :options.sync="options"
              :server-items-length="total"
              :loading="isLoading"
              class="elevation-1"
              hide-default-footer
            >
              <template v-slot:item.selectCheckbox="{ item }">
                <v-checkbox
                  ref="n"
                  v-model="deleteItems"
                  :value="item.province_id"
                  :key="item.province_id"
                  @click="addToDelete($event, item.province_id)"
                  v-show="checkIfOperationExistForModule('delete')"
                ></v-checkbox>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                      medium
                      color="grey"
                      @click="addToUpdate($event, item.province_id)"
                      v-show="checkIfOperationExistForModule('edit')"
                    >
                      mdi-pencil
                    </v-icon>
              </template>
            </v-data-table>
          </div>
      </v-col>
    </v-row>
    <v-row justify="center">
     <v-dialog
      v-model="toggleUpdateModal"
      max-width="600"
    >
      <template>
        <v-card>
                    <v-card-title>
                      <span class="headline">Update Province</span>
                    </v-card-title>
                    <v-form
                      ref="form"
                      @submit="updateProvinceData"
                      method="put"
                    >
                      <v-container class="px-50" fluid>
                        <v-text-field
                          v-model="updateProvince.stateName"
                          label="State/Province Name"
                          required
                          :rules="nameRules"
                        ></v-text-field>
                        <div style="color: red">
                          {{ upmessage1 }}
                        </div>
                        <v-text-field
                          v-model="updateProvince.stateCode"
                          :counter="2"
                          label="State/Province Code"
                        ></v-text-field>
                        <v-select
                          v-model="updateProvince.countryCode"
                          :items="states"
                          :menu-props="{ maxHeight: '400' }"
                          label="Country"
                        ></v-select>
                        <v-text-field
                          v-model="updateProvince.panelist_stateID"
                          :counter="4"
                          label="Panelist Code"
                        ></v-text-field>
                      </v-container>
                      <v-container class="px-50" fluid> </v-container>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          @click="updateProvinceData"
                          type="submit"
                        >
                          Update
                        </v-btn>
                        <v-btn class="close_button mx-2" text @click="toggleUpdateModal = false">
                          Close
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
	  </template>
     </v-dialog>
</v-row>
        <!-- v-data-table-ends -->
  </div>
</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: { Index, Buttons, Loading },
  data() {
    return {
      toggleUpdateModal:false,
      //////table data starts here////
      selected: [],
      options: {},
      sort: "",
      total: 0,
      editReportMode: false,
      fromDateMenu: false,
      fromDateVal: null,
      //  minDate: "2020-01-05",
      //  maxDate: "2019-08-30",
      headers: [
        {
          text: "",
          value: "selectCheckbox",
          width: "50px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "State/Province Name",
          value: "province_name",
          width: "200px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "State/Province Code",
          value: "province_code",
          width: "200px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Country",
          value: "country",
          width: "200px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Panelist Code",
          value: "panelist_code",
          width: "200px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Action",
          value: "actions",
          width: "150px",
          sortable: false,
          class: "v-data-table-header",
        },
      ],
      ////// table data ends here
      valid: true,
      checkbox: false,
      provinceName: [],
      province_data: [],
      isLoading: true,
      fullPage: true,
      dialog: false,
      dialog1: false,
      dialog2: false,
      deleteItems: [],
      checked_provinceId: [],
      checkcedstr: "",
      states: [],
      message1: "",
      message2: "",
      upmessage1: "",
      upmessage2: "",
      allSelected: false,
      addProvince: {
        stateName: null,
        stateCode: null,
        countryCode: null,
        panelist_stateID: null,
      },
      updateProvince: {
        stateId: null,
        stateName: null,
        stateCode: null,
        countryCode: null,
        panelist_stateID: null,
      },
      checked: false,
      nameRules: [(v) => !!v || "Name is required"],
    };
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    checkIfOperationExistForModule(type){
        return this.$utils.checkIfOperationExistForModule(this, type)
    },
    reset() {
      this.dialog = false;
      this.dialog1 = false;
      this.message1 = "";
      this.upmessage1 = "";
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
    },
    getProvince() {
      let check = this;
      const path = this.$url("PROVINCE");
      check.isLoading = true;
      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: check })
        .get(path)
        .then((res) => {
          check.isLoading = false;
          this.provinceName = res.data.payload.stateID[0];
          this.total = this.provinceName.length;
          if (Array.isArray(this.provinceName) && this.provinceName.length) {
            this.province_data = [];
            let i;
            for (i = 0; i < this.provinceName.length; i++) {
              let tempobj = {
                province_id: this.provinceName[i][0],
                province_name: this.provinceName[i][1],
                province_code: this.provinceName[i][2],
                country: this.provinceName[i][3],
                panelist_code: this.provinceName[i][4],
              };
              this.province_data.push(tempobj);
            }
          }
          if (res.data.payload.stateID[1].length) {
            res.data.payload.stateID[1].forEach(function (item) {
              let a = {
                text: item[1],
                value: item[0],
              };
              check.states.push(a);
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addProvinceData(e) {
      e.preventDefault();
      let check = this;
      this.isLoading = true
      if (
        this.addProvince.stateName != null &&
        this.addProvince.stateName != ""
      ) {
        this.$fetch({ requiresAuth: true, operation : 'add' , vueScope: check })
          .post(this.$url("PROVINCE"), this.addProvince)
          .then((result) => {
            check.isLoading = false
            console.log(result.data.payload[0]);
            this.message1 = result.data.payload[0].stateID;
            this.message2 = result.data.payload[0].stateName;
            if (this.message2 == null) {
              this.message1 = result.data.payload[0].stateID;
              this.dialog = true;
            } else {
              this.message1 = null;
              this.dialog = false;
              if (result.data.statusCode == 200 && this.message1 == null) {
                this.$swal.fire({
                  icon: "success",
                  title: "Record(s) added successfully",
                  text: result.data.payload.message,
                });
              }
              this.getProvince();
              this.dialog = false;
              this.reset();
            }
            console.log(result);
          }).catch((error) => {
           console.error(error);
           check.isLoading = false
           this.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Something went wrong!",
                });
        });
      }
    },
    addToUpdate(e, atchid) {
      let view = this;
      this.toggleUpdateModal = true
      view.provinceName.forEach(function (o, i) {
        if (o[0] == atchid) {
          view.updateProvince.stateName = o[1];
          view.updateProvince.stateCode = o[2];
          view.updateProvince.countryCode = o[3];
          view.updateProvince.panelist_stateID = o[4];
          view.updateProvince.stateId = o[0];
        }
      });
    },
    updateProvinceData(e) {
      e.preventDefault();
      let data = this;
      this.isLoading = true
      if (
        this.updateProvince.stateName != null &&
        this.updateProvince.stateName != ""
      ) {
        this.$fetch({ requiresAuth: true, operation : 'edit', vueScope: data })
          .put(this.$url("PROVINCE"), data.updateProvince)
          .then((result) => {
            data.isLoading = false
            this.upmessage1 = result.data.payload.stateID;
            this.upmessage2 = result.data.payload.stateName;
            if (this.upmessage2 == null) {
              this.upmessage1 = result.data.payload.stateID;
              this.dialog1 = true;
            } else {
              this.upmessage1 = null;
              this.dialog1 = false;
              if (result.data.statusCode == 200) {
                this.getProvince();
                this.$swal.fire({
                  icon: "success",
                  title: "Record(s) updated successfully",
                  text: result.data.payload.message,
                });
                this.toggleUpdateModal = false
              }
              this.reset();
            }
            console.log(result);
          }).catch((error) => {
           console.error(error);
           data.isLoading = false
           this.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Something went wrong!",
                });
        });
      }
    },
    addToDelete(e, atchid) {
      this.checkedthis = false;
      //e.target.checked
      if (e.target.checked) {
        if (this.checked_provinceId.indexOf(e.target.value) == -1) {
          this.checked_provinceId.push(e.target.value);
        }
      } else {
        this.checked_provinceId.splice(
          this.checked_provinceId.indexOf(e.target.value),
          1
        );
      }
      let checkcedstr = this.checked_provinceId.join(",");
      this.checkcedstr = checkcedstr;
      console.log(this.checkcedstr);
    },
    deleteProvince(e) {
      e.preventDefault();
      let check = this;
      this.isLoading = true
      console.log(this.deleteItems);
      this.$fetch({ requiresAuth: true , operation : 'delete', vueScope: check })
        .delete(this.$url("PROVINCE"), {
          params: { id: this.deleteItems },
        })
        .then((response) => {
          check.isLoading = false
          if (response.data.statusCode == 200) {
            this.getProvince();
            this.$swal.fire({
              icon: "success",
              title: "Selected record(s) has been deleted",
              text: response.data.payload.message,
            });
          }
          this.deleteItems = [];
        }).catch((error) => {
           console.error(error);
           check.isLoading = false
           this.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Something went wrong!",
                });
        });
    },
    selectAll: function () {
      let provinces = this.provinceName;
      if (this.allSelected == true) {
        if (Array.isArray(provinces) && provinces.length) {
          this.deleteItems = [];
          let i;
          for (i = 0; i < provinces.length; i++) {
            console.log(provinces[i]);
            this.deleteItems.push(provinces[i][0]);
          }
        }
      } else if (this.allSelected == false) {
        this.deleteItems = [];
      }
    },
    select: function () {
      this.allSelected = false;
    },
    submit() {
      this.$v.$touch();
    },
  },
  watch: {
 '$store.state.Rawdata.current_active_module': function(o) {
 if(o){
 this.getProvince(1);
 }
 },
 },
};
</script>
